<template>
    <h1>Auth</h1>
    <router-view />
</template>

<script>

export default {
  name: 'Index',

  components: {
  },

  data: () => ({
    //
  }),
}
</script>
